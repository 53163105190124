<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-14T11:24:47+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T08:05:23+02:00
@Copyright: Diversitude 2021
-->

<template>
    <span>
        <Layout>
            <b-row>
                <b-col cols="6">
                    <b-card title="Header">
                        <span class="heading-text">Header Text</span>
                        <div class="description-text">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iure minima delectus suscipit doloribus ad, odio nulla dicta quidem eos tempore dolorem necessitatibus fugiat? Quos et quidem maiores, reiciendis quam accusantium.</div>
                    </b-card>
                </b-col>
                <b-col cols="6">
                    <b-card title="System Text">

                    </b-card>
                </b-col>

            </b-row>

        </Layout>
    </span>
</template>

<script>
import Layout from "../layouts/main";
//import Table from '@/components/widgets/table/table.vue'
import { levelComputed, menuMethods, adminComputed, adminMethods, treeComponentComputed } from '@/state/helpers'
export default {
    name: "Typography",
    components:{
        Layout,
    },
    mounted(){
        this.setLoading(false);
    },
    data: () => ({
    }),
    methods:{
        ...menuMethods,
        ...adminMethods,
    },


    computed:{
        ...levelComputed,
        ...adminComputed,
        ...treeComponentComputed,
    },
}

</script>

<style>

</style>
